<template>
  <div class="page-common-body address-page-container" ref="wrapper">
    <div class="address__inner">
      <div class="page__title">收件人信息</div>
      <div class="page-address-main-body">
        <address-management></address-management>
      </div>
    </div>
  </div>
</template>

<script>
import addressManagement from "../address/_manage";
export default {
  name: "page-user-address-manage-list",
  components: {
    addressManagement
  },
  mounted() {
    const that = this;
    that.heightFullScreen(that.$refs.wrapper, false);
  }
};
</script>

<style></style>
